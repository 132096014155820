.date-range-picker-input {
  position: relative;

  input {
    padding-left: 2.25rem;
  }

  .icon--calendar {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.75rem;
    margin: auto;
  }

  .reset-filter {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.75rem;
    margin: auto;
    cursor: pointer;

    svg {
      color: var(--error);
    }
  }
}

.date-range-picker {
  display: flex;
  position: absolute;
  z-index: 9999;
  margin-top: 10px;
  background: white;
  border: 1px solid var(--gray-200);
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

  .date-range-picker-container {
    padding: 20px;
    position: relative;
  }

  .shortcuts {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    padding: 20px 0;
    min-width: 170px;
    border-right: 1px solid var(--gray-300);

    .btn {
      text-align: left;
      margin-bottom: 4px;
      padding-left: 8px;

      &.active {
        color: var(--primaryBgColor);
        font-weight: bold;
      }

      &:not(:disabled):not(.disabled):active:focus,
      &:not(:disabled):not(.disabled).active {
        box-shadow: none;
      }
    }
  }

  .date-range-picker-navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    position: absolute;
    width: calc(100% - 40px);

    .btn {
      padding: 0 4px;
      border-radius: 0;
      line-height: 1.2;
      font-size: 16px;
      border: 0;

      &.btn-secondary:hover {
        background: var(--primaryBgColor);
        border-color: var(--primaryBgColor);
      }

      &:not(:disabled):not(.disabled):active:focus,
      &:not(:disabled):not(.disabled).active {
        box-shadow: none;
      }
    }
  }

  .date-range-picker-months {
    display: flex;
    gap: 32px;

    .date-range-picker-month-container {
      display: flex;
      flex-direction: column;

      .date-range-picker-month-header {
        display: flex;
        justify-content: center;
        padding: 0 8px 15px;
      }
    }

    .date-range-picker-month {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }

    .btn-link {
      padding: 8px 10px;
      border-radius: 0;

      &:hover {
        color: var(--primaryBgColor);
      }

      &.today {
        font-weight: bold;
      }

      &.selected {
        background: var(--primaryBgColor);
        color: white;
      }

      &.is-in-range {
        background: var(--gray-200);
      }
    }
  }

  .date-range-picker-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 20px;
  }
}
