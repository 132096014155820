.filter-date-mask-input {
  .date-mask-input {
    position: relative;

    input {
      padding-left: 2.25rem;
    }

    .icon--calendar {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0.75rem;
      margin: auto;
    }
  }

  .filter {
    display: flex;
    gap: 12px;
    margin-top: 12px;

    .btn {
      flex: 1;
    }
  }
}
